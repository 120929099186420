import React from 'react'
import { Link } from 'gatsby'
import FaFacebookF from '../../assets/faFacebookF.svg';
import FaInstagram from '../../assets/faInstagram.svg';
import FaTwitter from '../../assets/faTwitter.svg';
import FaLinkedinIn from '../../assets/faLinkedinIn.svg';
import FaYoutube from '../../assets/faYoutube.svg';
import Logo from '../../assets/PillarFinal--white__footer.svg';

export default function Footer() {
  const yyyy = new Date().getFullYear();

  return (
    <footer>
      <div className="footer--wrapper">
        <div className="container">
          <div className="column">
            <div className="wrapper">
              <div className="wrapper--inner">
                <div className="footer--disclaimer">
                  <Logo />
                  <p>
                    The information on this website is for general information purposes only. Nothing on this site should be taken as legal advice for any individual case or situation. This information is not intended to create, and receipt or viewing does not constitute, an attorney-client relationship.
                  </p>
                </div>
                <div className="footer--social">
                  <a href="/" className="social-btn">
                    <FaFacebookF />
                  </a>
                  <a href="/" className="social-btn">
                    <FaInstagram />
                  </a>
                  <a href="/" className="social-btn">
                    <FaTwitter />
                  </a>
                  <a href="/" className="social-btn">
                    <FaLinkedinIn />
                  </a>
                  <a href="/" className="social-btn">
                    <FaYoutube />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="wrapper">
              <h3>Contact Info</h3>
              <div className="wrapper--text">
                <p className="footer--address">
                  5419 Route 9W <br />
                  Newburgh, NY 12550
                </p>
                <p className="footer--address">
                  290 Broadway, Suite 10<br />
                  Newburgh, NY 12550
                </p>
                <p>
                  <span className="footer--phone">845.522.8910</span>
                  <br />
                  <span className="footer--email">dreyerlawoffices@aol.com</span>
                </p>
              </div>
            </div>
          </div>
          <div className="column-2">
            <div className="wrapper">
              <h3>Practice Areas</h3>
              <div className="footer--navigation">
                <ul>
                  <li>
                    <Link to="/">Personal Injury</Link>
                  </li>
                  <li>
                    <Link to="/">Brain & Head Injuries</Link>
                  </li>
                  <li>
                    <Link to="/">Dog Bites</Link>
                  </li>
                  <li>
                    <Link to="/">Motor Vehicle Accidents</Link>
                  </li>
                  <li>
                    <Link to="/">Neck & Back Injuries</Link>
                  </li>
                  <li>
                    <Link to="/">Slip, Trip, and Fall Accidents</Link>
                  </li>
                  <li>
                    <Link to="/">Wrongful Death</Link>
                  </li>
                  <li>
                    <Link to="/">Criminal Defense</Link>
                  </li>
                  <li>
                    <Link to="/">Drug Crimes</Link>
                  </li>
                  <li>
                    <Link to="/">DWI Defense</Link>
                  </li>
                  <li>
                    <Link to="/">Traffic Violations</Link>
                  </li>
                  <li>
                    <Link to="/">White Collar Crimes</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="clear"></div>
      </div>
      <div className="copyright">
        <div className="container">
          <div className="copyright--left">
            Copyright &copy; {yyyy} Dreyer Law. All Rights Reserved.
            <br />
            Website Maintained by <a href="https://brandingcompanyllc.com" target="_blank">Branding Company, LLC</a>.
          </div>
          <div className="copyright--right">
            <Link to='/'>Home</Link>
            <Link to='/about'>Attorneys</Link>
            <Link to='/sitemap.xml'>Site Map</Link>
            <Link to='/privacy'>Privacy Policy</Link>
          </div>
        </div>
      </div>
    </footer>
  )
}